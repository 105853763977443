export const SET_DATA = 'SET_DATA';
export const SET_VALUE = 'SET_VALUE';

export const setData = data => ({
	type: SET_DATA,
	data,
});

export const setValue = (section, key, value) => ({
	type: SET_VALUE,
	section,
	key,
	value,
});
