import axios from 'axios';
import { GetURLParamValue } from '@blueprintnyc/bpnyc-common/ui_utils';

export const createAxiosConfig = () => {
	// const {} = options;
	const config = { headers: {} };
	const token = getToken();
	if (token) config.headers['Authorization'] = `Bearer ${token}`;
	return config;
};

export const FetchGet = async url => {
	const config = createAxiosConfig();
	return (await axios.get(url, config)).data;
};

export const FetchPost = async (url, data) => {
	const config = createAxiosConfig();
	return (await axios.post(url, data, config)).data;
};

export const FindScenarioData = (data, workshop) => {
	return Object.keys(data).reduce((acc, curr) => {
		if (curr.includes(workshop)) {
			acc[curr] = data[curr];
		}
		return acc;
	}, {});
};

export function getToken() {
	const token = localStorage.getItem('ClientToken');
	const urlToken = GetURLParamValue('token');
	return urlToken || token;
}

export const ReduceByKey = key => (acc, curr) => {
	acc[curr[key]] = curr;
	return acc;
};

export const FlattenContentToFields = (scenario, content) => {
	const fields = [];
	content[scenario].forEach(section => {
		if (section.rows) {
			section.rows.forEach(question => {
				fields.push(`${scenario}-${section.label}-${question.label}`);
			});
		}
	});
	return fields;
};

export const FlattenDataToFieldsObj = (scenario, sections) => {
	const fields = {};
	Object.keys(sections).forEach(section => {
		Object.keys(sections[section]).forEach(question => {
			fields[`${scenario}-${section}-${question}`] = sections[section][question];
		});
	});
	return fields;
};

export const fmtScores = scores => {
	return scores.map((score, idx) => {
		return <span key={idx}>{score}</span>;
	});
};
