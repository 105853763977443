/* eslint-disable no-useless-computed-key */
import { handleCSVDownload } from '@blueprintnyc/bpnyc-common/ui_utils/CSV';
import { FetchGet } from './helpers';

import config from './config';

import rstForms from './rstContent.json';
import ripcForms from './ripcContent.json';
import cvrmForms from './cvrmContent.json';
const contentChoices = {
	'RST': rstForms,
	'RIPC': ripcForms,
	'CVRM': cvrmForms,
};

const CSVDownload = props => {
	const downloadCSV = (track, workshop) => {
		const content = contentChoices[track][workshop];
		const fieldMap = prepFieldMap(content);

		FetchGet(config.server + `/api/worksheet/${track}/${workshop}/csv`)
			.then(({ data }) => {
				const fmtData = data.map(record => {
					const questions = flattenData(record.sections);
					delete record.sections;
					return { ...record, ...questions };
				});

				const fileName = `${track}-${workshop}-${Date.now()}`;

				const downloadProps = {
					data: fmtData,
					fieldMap,
					fileName,
				};
				console.log('Download Props', downloadProps);
				handleCSVDownload(downloadProps);
			})
			.catch(console.error);
	};

	return Object.keys(contentChoices).map((track, idx) => {
		return (
			<div key={idx}>
				<h2 style={{ margin: '15px' }}>{track}</h2>
				<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
					{Object.keys(contentChoices[track]).map((workshop, idx) => {
						return (
							<button
								onClick={() => downloadCSV(track, workshop)}
								style={{ margin: '1%', width: '25%', cursor: 'pointer' }}
								key={idx}
							>
								{workshop}
							</button>
						);
					})}
				</div>
			</div>
		);
	});
};

export default CSVDownload;

export function prepFieldMap(content) {
	const fieldMap = {
		datetime_updated: 'Last Updated',
		first_name: 'First Name',
		last_name: 'Last Name',
		email: 'Email',
		district: 'District',
		track: 'Track',
		workshop: 'Workshop',
		_order: ['Last Updated', 'First Name', 'Last Name', 'Email', 'District', 'Track', 'Workshop'],
	};

	content.form.forEach(section => {
		if (section.rows) {
			section.rows.forEach(question => {
				const fullLabel = question.label;
				fieldMap[fullLabel] = fullLabel;
				fieldMap._order.push(fullLabel);
			});
		}
	});
	return fieldMap;
}

function flattenData(sections) {
	const result = Object.values(sections).reduce((acc, curr) => {
		return { ...acc, ...curr };
	}, {});
	return result;
}
