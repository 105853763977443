import { AutoSaveTextArea } from '@blueprintnyc/bpnyc-common';

import '../styles/textarea.scss';
import parse from 'html-react-parser';

const TextArea = ({ value, onChange, onSubmit, label }) => {
	return (
		<div className={'textarea'} style={{ display: 'flex', flexDirection: 'column' }}>
			<p>{parse(label)}</p>
			<AutoSaveTextArea
				placeholder={'Enter response here...'}
				onSubmit={onSubmit}
				onChange={onChange}
				value={value}
			/>
		</div>
	);
};

export default TextArea;
