import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import CSV from './CSV';
import WISIWIGJson from './components/WISIWIG-JSON';
import { NotificationContainer } from 'react-notifications';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import 'react-notifications/lib/notifications.css';

const store = createStore();

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<Switch>
				<Route exact path="/csv" component={CSV} />
				<Route path="/cvrm/json" component={WISIWIGJson} />
				<Route path="/" component={App} />
			</Switch>
			<NotificationContainer />
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
