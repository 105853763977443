import React, { useState } from 'react';
import { Form } from './';
import initialContent from '../cvrmContent.json';

const WISIWIGJson = () => {
	const [jsonContent, setJsonContent] = useState(JSON.stringify(initialContent, null, 2));
	const [selectedWorkshop, setSelectedWorkshop] = useState('leadboldly');
	const [previewError, setPreviewError] = useState(null);

	const handleJsonChange = event => {
		setJsonContent(event.target.value);
		try {
			JSON.parse(event.target.value);
			setPreviewError(null);
		} catch (error) {
			setPreviewError('Invalid JSON format');
		}
	};

	const handleDownload = () => {
		try {
			const blob = new Blob([jsonContent], { type: 'application/json' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'cvrmContent.json';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Download failed:', error);
		}
	};

	const renderPreview = () => {
		try {
			const parsedContent = JSON.parse(jsonContent);
			const workshopContent = parsedContent[selectedWorkshop];

			if (!workshopContent) {
				return <div>No content available for selected workshop</div>;
			}

			const formProps = {
				workshop: selectedWorkshop,
				content: workshopContent.form,
				initData: {},
				onSubmit: () => console.log('Preview mode - submit disabled'),
				setData: () => console.log('Preview mode - setData disabled'),
			};

			return (
				<div>
					<h2 style={{ color: workshopContent.titleColor }}>{workshopContent.title}</h2>
					<div dangerouslySetInnerHTML={{ __html: workshopContent.instructions }} />
					<Form {...formProps} />
				</div>
			);
		} catch (error) {
			return <div>Error parsing JSON</div>;
		}
	};

	return (
		<div style={{ display: 'flex', gap: '20px', padding: '20px' }}>
			<div style={{ flex: 1 }}>
				<h3>JSON Editor</h3>
				<div style={{ marginBottom: '10px' }}>
					<button onClick={handleDownload}>Download JSON</button>
				</div>
				<textarea
					value={jsonContent}
					onChange={handleJsonChange}
					style={{
						width: '100%',
						height: '80vh',
						fontFamily: 'monospace',
						fontSize: '14px',
					}}
				/>
				{previewError && <div style={{ color: 'red' }}>{previewError}</div>}
			</div>

			<div style={{ flex: 1 }}>
				<h3>Preview</h3>
				<select
					value={selectedWorkshop}
					onChange={e => setSelectedWorkshop(e.target.value)}
					style={{ marginBottom: '10px' }}
				>
					{Object.keys(JSON.parse(jsonContent)).map(key => (
						<option key={key} value={key}>
							{key}
						</option>
					))}
				</select>
				<div className="worksheet-container">{renderPreview()}</div>
			</div>
		</div>
	);
};

export default WISIWIGJson;
