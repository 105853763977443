import formReducer from './form.reducer';
import usersReducer from './users.reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
	form: formReducer,
	users: usersReducer,
});

export default rootReducer;
