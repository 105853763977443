import { SET_DATA, SET_VALUE } from '../actions/form.action';

const FormReducer = (state = { sections: {} }, action) => {
	const { data, section, key, value, type } = action;
	switch (type) {
		case SET_DATA:
			return { ...data };
		case SET_VALUE:
			const newSection = {
				...state.sections[section],
				[key]: value,
			};

			if (!newSection[key]) delete newSection[key];

			return {
				...state,
				sections: {
					...(state.sections || {}),
					[section]: { ...newSection },
				},
			};

		default:
			return state;
	}
};

export default FormReducer;
