import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/root.reducer';

const logger = createLogger({ collapsed: true });

const Store = (preloadedState = {}) => {
	return createStore(
		rootReducer,
		preloadedState,
		composeWithDevTools(applyMiddleware(thunk, logger))
	);
};

export default Store;
