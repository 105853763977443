import { Collapse } from 'antd';
import { AutoSaveTextArea } from '@blueprintnyc/bpnyc-common';

import '../styles/textareaCollapse.scss';

const { Panel } = Collapse;

const TextAreaCollapse = ({ label, value, onChange, onSubmit }) => {
	return (
		<Collapse className={'textarea-collapse'} collapsible="header">
			<Panel
				className={'textarea-collapse-panel'}
				style={{ borderRadius: '5px' }}
				header={label}
				key="1"
			>
				<AutoSaveTextArea
					placeholder={'Write response here...'}
					onSubmit={onSubmit}
					onChange={onChange}
					value={value}
				/>
			</Panel>
		</Collapse>
	);
};

export default TextAreaCollapse;
