import { connect } from 'react-redux';

export const totalKOL = ['Overall Call Quality - Overall Score', 'Selling Skills - Overall Score'];

export const totalATS = [
	'Portfolio Selling - Overall Score',
	'Product Knowledge - Overall Score',
	'Selling Skills - Overall Score',
	'Appropriately and compliantly discussed brand messaging Effective use of resources',
];

export const totalEL = [
	'Portfolio Selling - Overall Score',
	'Product Knowledge - Overall Score',
	'Selling Skills - Overall Score',
	'Appropriately and compliantly discussed brand messaging Effective use of resources',
];

const ScenarioTotal = ({ passedScenario, scenario, sections }) => {
	const fmtScenario = scenario || passedScenario;
	let count = 0;
	const sum = Object.keys(sections).reduce((acc, section) => {
		Object.keys(sections[section]).forEach(question => {
			switch (fmtScenario) {
				case 'KOL':
					if (totalKOL.includes(question)) {
						acc += sections[section][question];
						count++;
					}
					break;
				case 'ATS':
					if (totalATS.includes(question)) {
						acc += sections[section][question];
						count++;
					}
					break;
				case 'EL':
					if (totalEL.includes(question)) {
						acc += sections[section][question];
						count++;
					}
					break;
				default:
					break;
			}
		});
		return acc;
	}, 0);

	const total = Math.round(sum / count) || 0;
	return <p className="form-scenarioTotal">{total}</p>;
};

const msp = ({ form: { sections, scenario } }) => ({ sections, scenario });
const mdp = dispatch => ({});

export default connect(msp, mdp)(ScenarioTotal);
