import {
	DELETE_USER,
	DELETE_ME,
	RESET_USERS,
	SET_USER,
	SET_USERS,
	SET_ME,
} from '../actions/users.action';

const initialState = {
	me: {}, // from GO server
	usersByID: {}, // everyone else from local server
};

const UserReducer = (state = initialState, action) => {
	Object.freeze(state);
	const { me, user, usersByID, type } = action;
	const stateDup = { ...state };

	switch (type) {
		case DELETE_USER:
			delete stateDup.usersByID[user.id];
			return stateDup;
		case DELETE_ME:
			stateDup.me = {};
			return stateDup;
		case RESET_USERS:
			stateDup.usersByID = {};
			return stateDup;
		case SET_USER:
			stateDup.usersByID[user.id] = user;
			return stateDup;
		case SET_USERS:
			stateDup.usersByID = usersByID;
			return stateDup;
		case SET_ME:
			stateDup.me = me;
			return stateDup;
		default:
			return state;
	}
};

export default UserReducer;
