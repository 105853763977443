import { connect } from 'react-redux';
import { RadioButton, TextAreaCollapse, TextArea, TextInput } from './index';
import { setValue } from '../redux/actions';

const FormRow = ({ label, section, options, setValue, value, type, onSubmit, name, readOnly }) => {
	let renderContent;
	const rowValue = value(section, label);

	switch (type) {
		case 'radioButton':
			renderContent = (
				<RadioButton
					onSubmit={onSubmit}
					onChange={value => setValue(section, label, value)}
					value={rowValue}
					options={options}
					label={label}
				/>
			);
			break;

		case 'text':
			renderContent = (
				<TextInput
					label={label}
					onChange={e => setValue(section, label, e)}
					onSubmit={onSubmit}
					value={rowValue}
					name={name}
					readOnly={readOnly}
				/>
			);
			break;

		case 'textarea':
			renderContent = (
				<TextArea
					label={label}
					onChange={e => setValue(section, label, e)}
					value={rowValue}
					onSubmit={onSubmit}
				/>
			);
			break;

		case 'textareaCollapse':
			renderContent = (
				<TextAreaCollapse
					label={label}
					onChange={e => setValue(section, label, e)}
					onSubmit={onSubmit}
					value={rowValue}
				/>
			);
			break;

		default:
			break;
	}

	return <div className={'form-section-row'}>{renderContent}</div>;
};

const msp = ({ form }) => ({
	value: (section, question) => form.sections[section] && form.sections[section][question],
});
const mdp = dispatch => ({
	setValue: (section, key, value) => dispatch(setValue(section, key, value)),
});

export default connect(msp, mdp)(FormRow);
